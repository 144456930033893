/* swiper.css */
.swiper {
    width: 100%;
    height: 100vh;
} 

@keyframes float {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  
  .welcome-screen {
    --background: black;
    position: relative;
    height: 100%;
  }
  
  .content {
    color:white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;
  }
  
  .title {
    color: white;
    font-family: 'Roboto', sans-serif;
  }
  
  .logo-container {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    transform: translateY(-50%);
  }
  
  .logo {
    max-width: 80%;
    animation: float 2s ease-in-out infinite;
  }
  
  .footer {
    position: absolute;
    bottom: 0;
    left:0;
    width: 100%;
    padding: 20px;
    text-align: center;
  }
  
  .start-button {
    --background: black;
    --background-activated: black;
    --color: white;
    --box-shadow: 0 0 10px white;
    margin-bottom: 20px;
  }
  
  .ion-text-center a {
    color: white;
  } 

  .button-container {
    position: fixed;
    right: 20px;
    bottom: 20px;
    display: flex;
    gap: 10px;
  }

/* .swiper-slide {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
} */