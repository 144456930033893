/* .card {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: opacity 0.2s ease-out;
    position: absolute;
    width: 90vw;
    height: 80%; 
    padding:0;
    margin-top:0;
    margin-left:auto;
    margin-right:auto;
} */

.card {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: opacity 0.2s ease-out;
    position: absolute;
    width: 90vw;
    height: 80%; /* Subtract the height of the IonTabBar */
    padding:0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.buttons{
    margin-bottom:0;
}

.container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
/* .IonContent {
    padding: 0;
    margin: 0;
} */

/* .card-buttons {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px;
}

.card-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
} */